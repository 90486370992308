import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const AdminState = {
  addAdmin: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-profile`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  getAdmin: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(`${BASE_URL}/api/v1/admin/get-profile`, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // console.log("response", response);
    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  loginAdmin: async (requestOptions) => {
    // FETCH API CALL For all notes
    // console.log("requestOptions", requestOptions);

    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/login-profile`,
      requestOptions,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response;
    // console.log("datas", datas);
    return datas;
  },

  updateAdmin: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/update-profile/${id}`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
  },

  deleteAdmin: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-profile/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },
};

export default AdminState;
