import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const HomeBannerState = {
  addHomeBanner: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-banner`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response-ad", response);
    let datas = response.data;
    // console.log("datas-ad", datas);
    return datas;
  },

  getHomeBanner: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(`${BASE_URL}/api/v1/admin/get-banner`, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // console.log("response-get", response);
    let datas = response.data.data;
    // console.log("datas-get", datas);
    return datas;
  },

  deleteHomeBanner: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-banner/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  updateHomeBanner: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/update-banner/${id}`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
  },
};

export default HomeBannerState;
