import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const NotificationState = {
  getNotification: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/admin/get-notification`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  addUserNotification: async (requestOptions) => {
    // console.log("requestOptions", requestOptions);

    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/add-user-notification`,
      requestOptions,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);

    let datas = response.data;
    // console.log("datas", datas);

    return datas;
  },

  getSingleNotification: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/admin/get-single-notification`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  deleteNotification: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-notification/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  deleteUserNotification: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-user-notification/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    console.log("response", response);
    let datas = response;
    console.log("datas", datas);
    return datas;
  },
};

export default NotificationState;
