import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const BrandState = {
  addBrand: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-brand`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response-ad", response);
    let datas = response.data;
    // console.log("datas-ad", datas);
    return datas;
  },

  getBrand: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(`${BASE_URL}/api/v1/admin/get-brand`, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // console.log("response-get", response);
    let datas = response.data.data;
    // console.log("datas-get", datas);
    return datas;
  },

  deleteBrand: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-brand/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  updateBrand: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/update-brand/${id}`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
  },

  brandStatus: async (id, requestOptions) => {
    // console.log("requestOptions", requestOptions);

    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/update-brand-status/${id}`,
      requestOptions,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);

    let datas = response;
    // console.log("datas", datas);

    return datas;
  },
};

export default BrandState;
