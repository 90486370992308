import "./App.css";
import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Loader from "./Components/Layout/Loader";

// Dashboard route
const PostMessageList = React.lazy(() =>
  import("./Components/Dashboard/DashScreens/PostMessage/PostMessageList")
);
const UserNotificationList = React.lazy(() =>
  import(
    "./Components/Dashboard/UserDashboard/UserNotification/UserNotificationList"
  )
);
const SingleUserList = React.lazy(() =>
  import("./Components/Dashboard/UserDashboard/SingleUserList")
);
const NotificationList = React.lazy(() =>
  import("./Components/Dashboard/DashScreens/Notification/NotificationList")
);
const AdminLogin = React.lazy(() =>
  import("./Components/Dashboard/AdminLogin")
);
const AdminList = React.lazy(() =>
  import("./Components/Dashboard/DashScreens/Admin/AdminList")
);
const BrandsList = React.lazy(() =>
  import("./Components/Dashboard/DashScreens/Brands/BrandsList")
);
const BannerList = React.lazy(() =>
  import("./Components/Dashboard/DashScreens/Banner/BannerList")
);
const UserList = React.lazy(() =>
  import("./Components/Dashboard/DashScreens/User/UserList")
);
const ContactList = React.lazy(() =>
  import("./Components/Dashboard/DashScreens/Contact/ContactList")
);
const WaContactList = React.lazy(() =>
  import("./Components/Dashboard/DashScreens/WaContact/WaContactList")
);

// Normal route
const About = React.lazy(() => import("./Components/Pages/About"));
const Contact = React.lazy(() => import("./Components/Pages/Contact"));
const Login = React.lazy(() => import("./Components/Pages/Login"));
const Register = React.lazy(() => import("./Components/Pages/Register"));
const Home = React.lazy(() => import("./Components/Pages/Home"));
const Error = React.lazy(() => import("./Components/Elements/Error"));
const AlertPopup = React.lazy(() => import("./Components/Elements/AlertPopup"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffff",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "gray",
            },
            "&:hover fieldset": {
              borderColor: "blue",
            },
            "&.Mui-focused fieldset": {
              borderColor: "gray",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "gray",
            },
            "&:hover fieldset": {
              borderColor: "blue",
            },
            "&.Mui-focused fieldset": {
              borderColor: "gray",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1976d2", // Default border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#115293", // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f50057", // Border color on focus
          },
        },
      },
    },
  },
});

const App = () => {
  const [alert, setAlert] = useState(null);
  const showAlert = (type, message) => {
    setAlert({
      type: type,
      msg: message,
    });
    setTimeout(() => {
      setAlert(null);
    }, 5000);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <AlertPopup alert={alert} setAlert={setAlert} />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route exact path="/" element={<Home showAlert={showAlert} />} />
              <Route
                exact
                path="/about-us"
                element={<About showAlert={showAlert} />}
              />
              <Route
                exact
                path="/contact-us"
                element={<Contact showAlert={showAlert} />}
              />
              <Route
                exact
                path="/login"
                element={<Login showAlert={showAlert} />}
              />
              <Route
                exact
                path="/register"
                element={<Register showAlert={showAlert} />}
              />

              {/* User panel */}
              <Route
                path="/dashboard/user-panel"
                element={<SingleUserList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/user_notifications"
                element={<UserNotificationList showAlert={showAlert} />}
              />

              {/* Dashboard path */}
              <Route
                path="/admin_login"
                element={<AdminLogin showAlert={showAlert} />}
              />

              <Route
                path="/dashboard/admin-panel"
                element={<AdminList showAlert={showAlert} />}
              />

              {/* Nested Routes inside Dashboard */}
              <Route
                path="/dashboard/brands-list"
                element={<BrandsList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/banner-list"
                element={<BannerList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/users-list"
                element={<UserList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/user-support"
                element={<ContactList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/contact-list"
                element={<WaContactList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/notifications"
                element={<NotificationList showAlert={showAlert} />}
              />

              <Route
                path="/dashboard/admin/notify_to_users"
                element={<PostMessageList showAlert={showAlert} />}
              />

              {/* 404 Not Found */}
              <Route exact path="*" element={<Error showAlert={showAlert} />} />
            </Routes>
          </Suspense>
          {/* <WhatsAppContact /> */}
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
