import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const UserState = {
  addUser: async (requestOptions) => {
    // console.log("requestOptions", requestOptions);

    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/user/create-profile`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);

    // console.log("response", response);
    let datas = response.data;
    // console.log("datas", datas);
    return datas;
  },

  getUser: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(`${BASE_URL}/api/v1/user/get-profile`, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // console.log("response", response);
    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  getUserById: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/user/get-profile-id/${id}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    console.log("response", response);
    let datas = response.data.user;
    // console.log("datas", datas);
    return datas;
  },

  updateUser: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    let response = await axios.put(
      `${BASE_URL}/api/v1/user/update-profile/${id}`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data;
    // console.log("datas", datas);
    return datas;
  },

  loginUser: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/user/login-profile`,
      requestOptions,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response;
    // console.log("datas", datas);
    return datas;
  },

  deleteUser: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/user/delete-profile/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },
};

export default UserState;
