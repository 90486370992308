import ServicesContext from "./ServiceContext";
import AdminState from "./ContextStore/AdminState";
import HomeBannerState from "./ContextStore/HomeBannerState";
import UserState from "./ContextStore/UserState";
import WaContactState from "./ContextStore/WaContactState";
import ContactState from "./ContextStore/ContactState";
import NotificationState from "./ContextStore/NotificationState";
import BrandState from "./ContextStore/BrandState";

const ServiceProvider = ({ children }) => {
  return (
    <ServicesContext.Provider
      value={{
        AdminState: AdminState,
        UserState: UserState,
        ContactState: ContactState,
        HomeBannerState: HomeBannerState,
        WaContactState: WaContactState,
        NotificationState: NotificationState,
        BrandState: BrandState,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export default ServiceProvider;
